<template>
  <base-modal class="modal-copy-plan" :isOpened="isOpen" @close="$emit('close')">
    <template v-slot:header>
      <h3>LIBERAR PLANO</h3>
    </template>
    <template v-slot:content>
      <div class='w-100 mb-3'>
        <base-input-text
          class='w-100'
          v-model="plan.dueDate"
          label="Data de validade do plano"
          type="date"
          block
        />
      </div>
      <div class='d-flex align-items-end w-100'>
        <base-input-select
          class='w-50 mr-2'
          v-model="plan.plan"
          label="Plano"
          placeholder="Selecione o plano"
          :options="plans"
          _key="key"
          _value="value"
        />
        <div class='w-50 d-flex flex-column'>
          <label class='label'>Preço</label>
          <money
            class='form-control_price'
            v-model="plan.price"
            v-bind="money"
          >
          </money>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <base-button
        class='w-50'
        @click="$emit('close')"
        block
        danger
      >
        Cancelar
      </base-button>

      <b-overlay
        :show="addedCopyPlan"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block w-50"
      >
        <base-button
          :disabled="!isValid()"
          @click="$emit('submit', plan)"
          block
          success
        >
          Salvar
        </base-button>
      </b-overlay>

    </template>
  </base-modal>
</template>

<script>

export default {
  name: 'ModalCopyPlan',
  props: {
    isOpen: { type: Boolean, default: false },
    addedCopyPlan: { type: Boolean, default: false },
  },
  data(){
    return {
      plan: {},
      plans:[
        { key: "AN1HO" , value: "Plano anual" },
        { key: "PROMO" , value: "Plano promocional" },
        { key: "OUTRO" , value: "Outro" },
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: true
      }
    }
  },
  emits: ['close', 'submit'],
  methods: {
    isValid(){
      const price = this.plan.price ? Number(this.plan.price.toString().replace(/\D+/g, '')) : 0
      return this.plan.dueDate && this.plan.plan && price > 0
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-copy-plan {
  /deep/ header {
    padding: 0.5rem;

    h3 {
      font-weight: bold;
    }
  }

  /deep/ main {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }
  .label{
    font-size: 18px;
    font-weight: 700;
  }
  .form-control_price{
    height: 48px;
    border-radius: 4px;
    border: 1px solid #c0c3c7;
    padding: 10px;
  }

  /deep/ footer {
    display: flex;
    gap: 16px;
    padding: 1rem;
  }
}
</style>
