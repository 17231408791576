<template>
  <section  class='w-100 register'>
    <section>
      <section class='mb-5 mt-3'>
        <b-form-input
          v-if='showPhoneField'
          id='phone'
          class='input_code'
          :state='validCode'
          v-model='cpfChange'
          v-mask="'###.###.###-##'"
          aria-describedby='input-live-help input-live-feedback'
          placeholder='Digite o cpf'
          trim>
        </b-form-input>
        <b-form-invalid-feedback :state='validCode' class='w-100 position-absolute text-left' id='phone'>
          Código de segurança inválido
        </b-form-invalid-feedback>
      </section>

      <section>
        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class=' w-100'
        >
          <base-button
            :disabled='!validate()'
            @click='updateCPF()'
            class='py-2 w-100 btn_chang d-flex justify-content-center'>
            <b-icon font-scale="1.2" class='pa-1 mr-3' icon="pencil-fill"></b-icon>
            <span class='text_btn'>Atualizar CPF</span>
          </base-button>
        </b-overlay>
      </section>
    </section>
  </section>
</template>

<script>

import { mask } from 'vue-the-mask'
import { mapActions } from 'vuex'

export default {
  namer: 'ModalChangeCPF',
  directives: { mask },
  components: {},
  props: {
    cpf: { type: String, default: null },
    userId: { type: String, default: null },
  },
  computed: {
    validCode() {
      return this.cpfChange && this.cpfChange.length >= 0
    }
  },
  data() {
    return {
      cpfChange: '',
      loading: false,
      showPhoneField: false,
    }
  },
  validations() {
    return {}
  },
  created() {
    this.cpfChange = this.cpf
    this.$store.dispatch('access/updateError', '')
    this.$store.dispatch('access/updateMessage', '')
  },
  mounted() {
    this.cpfChange = this.cpf
    this.showPhoneField = true
  },
  methods: {
    ...mapActions('user', {
      handleUpdateCpf: 'handleUpdateCpf'
    }),
    validate() {
      return this.cpfChange && this.cpfChange.length >= 0
    },
    async updateCPF() {
      try {
        this.loading = true
        const body = {
          _id: this.userId,
          cpf: this.cpfChange.replace(/[^\d]/g, '')
        }
        const { message, errors } = await this.handleUpdateCpf(body)

        let variant = 'success'

        if(errors){
          variant = 'danger'
        }

        this.openDialogReleaseTrial = false
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 2000,
        })
        this.loading = false
         this.$emit('close')
      } catch (e) {

      }
    }
  }
}
</script>

<style lang='scss' scoped>
.input_code {
  height: 45px;
  border-radius: 8px;
  margin-right: 10px;
  padding-left: 10px!important;
  border: 1.5px solid #cccccc!important;
}
.register{
  min-height: 140px;
}
.btn_chang {
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  line-height: 24px;
i {
  color: #022245;
  margin-right: 6px;
}

img {
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
}
</style>
