<template>
  <div class="section-actions">
    <div class='d-flex flex-wrap'>
      <base-button
        @click="$emit('trial-plan')"
        success
        :disabled="user.usedTrial && user.usedTrial.length > 0"
      >
        Liberar teste grátis
      </base-button>

      <base-button
        @click="$emit('release-plan')"
        success
      >
        Liberar plano
      </base-button>

      <base-button
        @click="$emit('change-phone', user)"
        success
      >
        Atualizar telefone
      </base-button>

      <base-button
        @click="$emit('select-date-exemption-authentication', user)"
        success
      >
        Liberar autenticação
      </base-button>
    </div>

    <div class='d-flex flex-wrap'>
      <base-button
        @click="$emit('change-cpf', user)"
        success
      >
        Atualizar CPF
      </base-button>

      <base-button
        @click="$emit('delete-account', user)"
        success
      >
        Excluir conta
      </base-button>

      <base-button
        @click="$emit('block-user', {
          email: user.username,
          block: user.block,
          _id: user._id
        })"
        class="block-user"
        :class="{ blocked: user.block}"
        danger
      >
        {{user.block ? 'Desbloquear usuário' : 'Bloquear usuário'}}
      </base-button>

      <base-button
        @click="$emit('select-extra-access-date', user)"
        success
      >
        Atualizar dias extra de acesso
      </base-button>

      <base-button
        v-if="user && user.role === 'admin'"
        @click="goToRouter('/funcionalidades')"
        success
      >
        Funcionalidades
      </base-button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SectionActions',
  props: {
    user: { type: Object, required: true }
  },
  methods: {
    goToRouter(router) {
      this.$router.push({ path: router, query: {usuario: this.user._id} })
    },
  },
  emits: ['trial-plan', 'release-plan']
}
</script>

<style lang="scss" scoped>

.section-actions {

  button{
    width: 300px;
    margin: 0 8px 8px 0
  }
  .block-user {
    opacity: 0.7 !important;
    &.blocked{
      opacity: 1 !important;
    }
  }
}
</style>
