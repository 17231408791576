import { render, staticRenderFns } from "./ModalChangeCPF.vue?vue&type=template&id=7ed16958&scoped=true&"
import script from "./ModalChangeCPF.vue?vue&type=script&lang=js&"
export * from "./ModalChangeCPF.vue?vue&type=script&lang=js&"
import style0 from "./ModalChangeCPF.vue?vue&type=style&index=0&id=7ed16958&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed16958",
  null
  
)

export default component.exports