<template>
  <div v-if="user" class="view-user-details mt-2">
    <b-modal
      size="lg"
      id="selectDateExemptionAuthentication"
      header-bg-variant="info"
      header-text-variant="light"
      title="Liberar autenticação em dois fatores."
      ref="selectDateExemptionAuthentication"
      hide-footer
      scrollable
    >
      <div class='modal_font_size'>
        <div class='w-100 mb-3'>
          <base-input-text
            class='w-100'
            :min='currentDate'
            v-model="twoFactorAuthenticationReleaseDeadline"
            label="Não solicitar autenticação em dois fatores até qual data?"
            type="date"
            block
          />
        </div>

        <b-input-group class='d-flex w-100'>
          <b-button
            @click="closeModalSelectDateExemptionAuthentication()"
            class='w-50' variant='danger'
            block
          >
            Cancelar
          </b-button>

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='w-50 justify-content-center'
          >
            <b-button :disabled='!twoFactorAuthenticationReleaseDeadline' class='btn_save' @click='saveDateExemptionAuthentication' block>
              Salvar
            </b-button>
          </b-overlay>
        </b-input-group>
      </div>
    </b-modal>

    <b-modal
      size="lg"
      id="selectExtraAccessDate"
      header-bg-variant="info"
      header-text-variant="light"
      title="Liberar dias acesso extra"
      ref="selectExtraAccessDate"
      hide-footer
      scrollable
    >
      <div class='modal_font_size'>
        <div class='w-100 mb-3' v-if='currentDate'>
          <base-input-text
            class='w-100'
            v-model="quantityDaysExtraAccess"
            label="Liberar quantos dias de acesso extra?"
            placeholder="Digite a quantidade de dias de acesso extra"
            type="nunber"
            v-mask="['#########']"
            block
          />
        </div>

        <b-input-group class='d-flex w-100'>
          <b-button
            @click="closeModalSetSelectExtraAccessDate()"
            class='w-50' variant='danger'
            block
          >
            Cancelar
          </b-button>

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='w-50 justify-content-center'
          >
            <b-button :disabled='quantityDaysExtraAccess < 1' class='btn_save' @click='saveQuantityDaysExtraAccess()' block>
              Salvar
            </b-button>
          </b-overlay>
        </b-input-group>
      </div>
    </b-modal>

    <b-modal
      id="changePhone"
      size="lg"
      header-bg-variant="info"
      header-text-variant="light"
      title="Atualizar telefone"
      ref="changePhone"
      hide-footer
      scrollable
    >
      <change-phone
        :username='confirmationEmail'
        :phone='phone'
        @new-phone="closeModalChangePhone"
      />
    </b-modal>

    <dialog-confirmation
      :is-open="dialogBlock.open"
      @close="dialogBlock.open = false"
      @confirmed="handleBlockAndUnblockUser"
      :description="`
        Confirmar
        ${dialogBlock.block? 'desbloqueio': 'bloqueio'}
        do usuario
        ${dialogBlock.email}?
      `"
    />

    <dialog-confirmation
      description="Você está prestes a ativar o período de testes do HO Fácil Web para este usuário. Ao clicar no botão de confirmar o usuário terá acesso completo à plataforma durante os próximos 7 dias contados a partir da sua confirmação."
      :is-open="openDialogReleaseTrial"
      @confirmed="handleReleaseTrialPlan"
      @close="openDialogReleaseTrial = false"
    />

    <modal-copy-plan
      description="Você está prestes a ativar o período de testes do HO Fácil Web para este usuário. Ao clicar no botão de confirmar o usuário terá acesso completo à plataforma durante os próximos 7 dias contados a partir da sua confirmação."
      :is-open="openModal"
      :addedCopyPlan="addedCopyPlan"
      @submit="handleReleaseCopyPlan"
      @close="openModal = false"
    />

    <section-profile
      :user="user"
      ref='sectionProfile'
    />

    <b-modal
      id="modalChangeCPF"
      size="lg"
      header-bg-variant="info"
      header-text-variant="light"
      title="Atualizar CPF"
      ref="modalChangeCPF"
      hide-footer
      scrollable
    >
      <modal-change-c-p-f
        :cpf='cpfUpdate'
        :userId='userIdcpfUpdate'
        @close="closeModalCpfUpdate"
      />
    </b-modal>

    <dialog-confirmation
      :description="`Você realmente deseja excluir a conta: ${modalDeleteAccount.user.username}?`"
      :is-open="modalDeleteAccount.open"
      :loading='loading'
      @confirmed="deleteAccount"
      @close="modalDeleteAccount.open = false"
    />

    <section-actions
      v-if='!readOnly'
      :user="user"
      @trial-plan="openDialogReleaseTrial = true"
      @release-plan="openModal = true"
      @block-user="(data) => dialogBlock = { open: true, ...data }"
      @change-phone="(data) => setDataChangePhone(data)"
      @change-cpf="(data) => setDataChangeCPF(data)"
      @delete-account="(data) => setDeleteAccountd(data)"
      @select-date-exemption-authentication="(data) => setSelectDateExemptionAuthentication(data)"
      @select-extra-access-date="(data) => setSelectExtraAccessDate(data)"
    />

    <table-plans
      :user="user"
      :plans="userPlans"
      :plans-meta="userPlansMeta"
      :readOnly='readOnly'
      @change-plans="changePlans"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import ChangePhone from '@/views/Access/ChangePhone'
import ModalChangeCPF from '@/components/Users/ModalChangeCPF'
import { mask } from 'vue-the-mask'
import DialogConfirmation from '@/components/DialogConfirmation.vue'
import ModalCopyPlan from '@/components/UserDetails/ModalCopyPlan.vue'
import SectionActions from '@/components/UserDetails/Actions.vue';
import SectionProfile from '@/components/UserDetails/Profile.vue';
import TablePlans from '@/components/UserDetails/Plans.vue';

export default {
  name: 'UserDetails',
  components: { DialogConfirmation, ModalCopyPlan, SectionActions, SectionProfile, TablePlans, ChangePhone, ModalChangeCPF },
  directives: { mask },
  data(){
    return {
      modalDeleteAccount: {
        open: false,
        user: {
          _id: '',
          username: '',
        }
      },
      confirmationEmail: '',
      twoFactorAuthenticationReleaseDeadline: null,
      twoFactorAuthenticationReleaseDeadlineUserId: null,
      quantityDaysExtraAccess: null,
      quantityDaysExtraAccessUserId: null,
      planId: null,
      phone: '',
      cpfUpdate: '',
      userIdcpfUpdate: '',
      userId: '',
      addedCopyPlan: false,
      dialogBlock: {},
      openDialogReleaseTrial: false,
      loading: false,
      openModal: false,
      messages: {
        'User does not exist': 'Usuário não existe',
        'User has already used free trial': 'Usuário já utilizou do plano gratuito',
        'Trial plan successfully activated': 'Plano gratuito ativado com sucesso',
        'Trial successfully activated': 'Plano ativado com sucesso',
        'User without plan': 'Usuário não tem plano',
        'Error updating user': 'Erro ao bloquear/desbloquear usuário',
        'User updated successfully': 'Usuário bloqueado/desbloqueado com sucesso'
      }
    }
  },
  async created(){
    this.userId = this.$route.params._id;
    if(this.userId){
      await this.handleGetUser(this.userId);
      await this.handleGetUserPlans({
        userId: this.userId,
        sortBy: 'createdAt',
        sortIn: 'desc'
      });
    }
    const breadcrumb = {
      page: 'Usuários',
      subPage: {
        name: 'Usuários',
        active: false
      },
      tabs: [
        {
          name: 'Dados pessoais',
          active: true
        }
      ]
    }
    this.setBreadcrumb(breadcrumb)
  },
  computed: {
    currentDate() {
      const user = this.$store.getters['user/current']
      return moment(user.currentDate).format('YYYY-MM-DD')
    },
    currentUser() {
      return this.$store.getters['user/current']
    },
    readOnly(){
      return !!this.currentUser.functionalities.find((x) => x.name === 'Gerenciar usuário (somente leitura)')
    },
    ...mapGetters('user', {
      user: 'getUser',
    }),
    ...mapGetters('userPlans', {
      userPlans: 'userPlans',
      userPlansMeta: 'userPlansMeta',
    }),
  },
  methods: {
    ...mapActions('user', {
      handleGetUser: 'handleGetUser',
      handleUserById: 'handleUserById',
      handleUserProfileById: 'handleUserProfileById',
      handleUnblockUser: 'handleUnblockUser',
      handleBlockUser: 'handleBlockUser',
      handleDeleteAccount: 'handleDeleteAccount',
      handTwoFactorAuthenticationReleaseDeadline: 'handTwoFactorAuthenticationReleaseDeadline',
      handleSetQuantityDaysExtraAccess: 'handleSetQuantityDaysExtraAccess',
    }),
    ...mapActions('userPlans', {
      handleGetUserPlans: 'handleGetUserPlans',
      handleAdminReleaseTrialPlan: 'handleAdminReleaseTrialPlan',
      handleAdminReleaseCopyPlan: 'handleAdminReleaseCopyPlan'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb',
    }),

    async changePlans(page){
      const params = {
        ...this.userPlansMeta,
        userId: this.userId,
        skip: (page - 1) * this.userPlansMeta.limit,
        sortBy: 'createdAt',
        sortIn: 'desc'
      }

      await this.handleGetUserPlans(params);
    },

    async handleReleaseTrialPlan(){
      const response = await this.handleAdminReleaseTrialPlan({ userId: this.user._id })

      let message = ''
      let variant = ''

      if(response.message){
        message = this.messages[response.message] || 'Plano gratuito ativado com sucesso'
        variant = 'success'
      } if(response.errors){
        message = this.messages[response.errors] || 'Erro ao ativar plano gratuito'
        variant = 'danger'
      }

      this.openDialogReleaseTrial = false
      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      })

      this.openDialogReleaseTrial = false
      await this.handleGetUser(this.userId)
      await this.handleGetUserPlans({ userId: this.userId })
    },

    async handleReleaseCopyPlan(plan){
      try {
        this.addedCopyPlan = true;
        const response = await this.handleAdminReleaseCopyPlan({
          userId: this.user._id,
          dueDate: new Date(plan.dueDate),
          plan: plan.plan,
          price: plan.price,
          userReleasedPlan: this.currentUser.id,
        })

        let message = ''
        let variant = ''

        if(response.message){
          message = this.messages[response.message] || 'Plano ativado com sucesso'
          variant = 'success'
        } if(response.errors){
          message = this.messages[response.errors] || 'Erro ao ativar plano gratuito'
          variant = 'danger'
        }

        this.openDialogReleaseTrial = false
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        })

        this.openModal = false
        await this.handleGetUser(this.userId)
        await this.handleGetUserPlans({ userId: this.userId })
      } catch (e) {

      } finally {
        this.addedCopyPlan = false;
      }
    },

    async handleBlockAndUnblockUser() {
      let response;
      let message = '';
      let variant = 'success';

      if(this.dialogBlock.block) {
        response = await this.handleUnblockUser(this.dialogBlock._id);
      } else {
        response = await this.handleBlockUser(this.dialogBlock._id);
      }

      if(response.errors) {
        message = this.messages[response.errors];
        variant = 'danger';
      } else if(response.message) {
        message = this.messages[response.message];
      }

      await this.handleGetUser(this.userId)
      await this.handleGetUserPlans({ userId: this.userId })

      this.dialogBlock = {
        open: false,
        email: '',
        block: false,
        _id: '',
      };

      this.$bvToast.toast(message, {
        title: 'HOFácil WEB',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      })
    },

    async closeModalChangePhone() {
      this.$refs.changePhone.hide()
      this.$bvToast.toast(`Telefone do usuário ${this.confirmationEmail} atualizado com sucesso!`, {
        title: 'Atenção',
        variant: 'success',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      });
    },

    async closeModalCpfUpdate() {
      setTimeout(() =>{
        this.$refs.modalChangeCPF.hide()
      }, 2000)
      await this.handleGetUser(this.userId);
    },

    setDataChangePhone(user){
      this.phone = user.profile.phone;
      this.confirmationEmail = user.username;
      this.$refs.changePhone.show();
    },

    async setDataChangeCPF(user){
      this.userIdcpfUpdate = user._id
      const [userFind, userProfileFind] = await Promise.all([
        await this.handleUserById(user._id), await this.handleUserProfileById(user._id)
      ])

      const {user: userResponse } = userFind
      const {userProfile: userProfileResponse } = userProfileFind

      if(
        (userResponse && userResponse.profile && userResponse.profile.cpf && userResponse.profile.cpf.length > 0) ||
        (userProfileResponse && userProfileResponse.cpf && userProfileResponse.cpf.length > 0)
      ) {
        this.cpfUpdate = userProfileResponse && userProfileResponse.cpf && userProfileResponse.cpf.length > 0 ? userProfileResponse.cpf : userResponse.profile.cpf;
        this.$refs.modalChangeCPF.show();
        return
      }
      this.$bvToast.toast('O usuário ainda não cadastrou CPF.', {
        title: 'Atenção',
        variant: 'danger',
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000,
      })
    },

    setDeleteAccountd(user){
      this.modalDeleteAccount = {
        open: true,
        user: {
          _id: user._id || '',
          name: user.name || '',
          username: user.username || ''
        }
      }
    },

    closeModalSelectDateExemptionAuthentication(){
      this.$refs.selectDateExemptionAuthentication.hide()
    },

    closeModalSetSelectExtraAccessDate(){
      this.$refs.selectExtraAccessDate.hide()
    },

    setSelectDateExemptionAuthentication(user){
      this.twoFactorAuthenticationReleaseDeadlineUserId = user.id
      this.twoFactorAuthenticationReleaseDeadline = null
      this.$refs.selectDateExemptionAuthentication.show()
    },

    setSelectExtraAccessDate(user){
      const {endDateExtraAccessTime} = user
      this.quantityDaysExtraAccess = 0
      this.planId = null
      if (endDateExtraAccessTime) {
        this.quantityDaysExtraAccess =  this.$refs.sectionProfile.formatQuantityDaysExtraAccess(endDateExtraAccessTime)
      }

      if (user.plan && user.plan._id && user.plan.status && user.plan.status === 'paid') {
        this.planId = user.plan._id
      }
      this.quantityDaysExtraAccessUserId = user.id
      this.$refs.selectExtraAccessDate.show()
    },

    async saveQuantityDaysExtraAccess() {
      this.loading = true;
      const { data, status, errors } = await this.handleSetQuantityDaysExtraAccess(
        {
          userId: this.quantityDaysExtraAccessUserId,
          quantityDaysExtraAccess: this.quantityDaysExtraAccess,
          planId: this.planId
        }
      )
      this.loading = false;
      let message = errors
      let variant = 'danger'

      if (status === 200 || status === 201) {
        message = data.message
        variant = 'success'
      }

      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      this.closeModalSetSelectExtraAccessDate()
      await this.handleGetUser(this.userId)
      await this.handleGetUserPlans({ userId: this.userId })
    },

    async saveDateExemptionAuthentication() {
      this.loading = true;
      const { data, status, errors } = await this.handTwoFactorAuthenticationReleaseDeadline(
        {
          userId: this.twoFactorAuthenticationReleaseDeadlineUserId,
          twoFactorAuthenticationReleaseDeadline: this.twoFactorAuthenticationReleaseDeadline
        }
      )
      this.loading = false;
      let message = errors
      let variant = 'danger'

      if (status === 200 || status === 201) {
        message = data.message
        variant = 'success'
      }

      this.$bvToast.toast(message, {
        title: 'Atenção',
        variant: variant,
        toaster: 'b-toaster-top-center',
        autoHideDelay: 5000
      })
      this.closeModalSelectDateExemptionAuthentication()
      await this.handleGetUser(this.userId)
      await this.handleGetUserPlans({ userId: this.userId })
    },

    async deleteAccount(){
      try {
        this.loading = true
        const { message, errors } = await this.handleDeleteAccount(this.modalDeleteAccount.user._id)
        let variant = 'success'
        if(errors){
          variant = 'danger'
        }
        this.openDialogReleaseTrial = false
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        })
        this.modalDeleteAccount.open =  false
        this.loading = false
        setTimeout(async () => {
          await this.$router.push({ path: '/Usuarios' })
        }, 2000)
      } catch (e) {

      }
    },
  }
}
</script>

<style lang="scss" scoped>
.view-user-details{
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.modal_font_size{
  width: 100%;
  font-family: 'Montserrat',serif;
}
.btn_save {
  border: none;
  background-color: #CBD63E;
  color: #022245;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  line-height: 24px;
  padding: 7px 0;
  width: 98%;
  margin-left: 2%;
  i {
    color: #022245;
    margin-right: 6px;
  }

  img {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }
}
</style>
